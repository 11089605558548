import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ActivityMap } from 'state-domains/domain/subscription';
import { buildConfigurationActivityUrl } from 'state-domains/network';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken } from '../../../utils';

export const modifyConfigurationActivity = (
    id: string,
    payload: ActivityMap,
): Observable<ActivityMap> => {
    const obs = sendRequestWithXSRFToken(
        buildConfigurationActivityUrl(id),
        convertToSnake(payload),
        'PUT',
    );
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ActivityMap>) => {
            const res = convertToCamel<ActivityMap>(response);
            return observableOf(res);
        }),
    );
};
